import React from 'react';
// import { StaticImage } from 'gatsby-plugin-image'
import webDesign from '../images/web-design-orange.svg'
import brandDesign from '../images/logo_design_orange.svg'
import eCommerceDesign from '../images/e_commerce_design_orange.svg'
import socialMediaMarketing from '../images/social_media_marketing_orange.svg'

const rowPadding = {
    padding: "150px 0",
}

const pDefaultMargin = {
    margin: "25px 0 0",
    fontSize: "18px",
    lineHeight: "1.5",
    letterSpacing: ".5px"
}

export default function WhatWeOffer() {
    return (
        <>
            <section id='web_design' className='w-100 dot-pattern text-dark'>
                <div className='container'>
                    <div className='row flex-row-reverse g-5 row-padding' style={rowPadding}>
                        <div className='col-md-6 mt-0'>
                            <div className='ms-md-5 position-relative'>
                                {/* <StaticImage src='../images/choose-your-plan.png' className='img-fluid position-relative shadow w-100' alt='Choose Plan' style={{height: 320}}></StaticImage> */}
                                <img src={webDesign} className='img-fluid position-relative w-100' alt='Choose Plan' style={{height: 320, filter: "drop-shadow(rgba(0,0,0,.5)5px 5px 20px)"}}></img>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <h2 className='display-6 mb-4'>Web Design</h2>
                            <div className='line-divider'></div>
                            <p className='mb-4' style={pDefaultMargin}>
                                When a customer is searching for your company or business, the first thing they will do is check your website online. The way your website look speaks a lot about your company. When a website is designed beautifully and professionally, there’s a high chance that your customer will more likely be inclined to do business with you.
                            </p>
                            <a className="btn btn-orange default-padding shadow text-capitalize" href="/services/web-design" role="button">Explore now</a>
                        </div>
                    </div>
                </div>
            </section>     

            <section id='brand_design' className='container'>
                <div className='row g-5 row-padding' style={rowPadding}>
                    <div className='col-md-6 mt-0'>
                        <div className='me-md-5 position-relative'>
                            {/* <StaticImage src='../images/choose-your-theme.png' className='img-fluid position-relative shadow w-100' alt='Choose Theme' style={{height: 320}}></StaticImage> */}
                            <img src={brandDesign} className='img-fluid position-relative w-100' alt='Choose Theme' style={{height: 320, filter: "drop-shadow(rgba(0,0,0,.5)5px 5px 20px)"}}></img>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <h2 className='display-6 mb-4'>Brand Design</h2>
                        <div className='line-divider'></div>
                        <p className='mb-4' style={pDefaultMargin}>
                            Branding or brand design is not just about a name or a company logo. It is the core representation of your business. Our Graphic Design Team will work closely with you to fully understand how we can translate and design your brand that will perfectly reflect your business and the values it represents. 
                        </p>
                        <a className="btn btn-orange default-padding shadow text-capitalize" href="/services/brand-design" role="button">Explore now</a>
                    </div>
                </div>
            </section>

            <section id='e_commerce' className='w-100 dot-pattern text-dark'>
                <div className='container'>
                    <div className='row flex-row-reverse g-5 row-padding' style={rowPadding}>
                        <div className='col-md-6 mt-0'>
                            <div className='ms-md-5 position-relative'>
                                {/* <StaticImage src='../images/choose-your-domain.jpg' className='img-fluid position-relative shadow w-100' alt='Choose Domain' style={{height: 320}}></StaticImage> */}
                                <img src={eCommerceDesign} className='img-fluid position-relative w-100' alt='Choose Domain' style={{height: 320, filter: "drop-shadow(rgba(0,0,0,.5)5px 5px 20px)"}}></img>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <h2 className='display-6 mb-4'>E-commerce Design</h2>
                            <div className='line-divider'></div>
                            <p className='mb-4' style={pDefaultMargin}>
                                Our E-Commerce websites are designed to increase income and make the overall operation of your company easier and more efficient. When you market your products or services across different channels and avenues, your business will grow exponentially with easy to control stocks and websites from a customized platforms.
                            </p>
                            <a className="btn btn-orange default-padding shadow text-capitalize" href="/services/e-commerce-design" role="button">Explore now</a>
                        </div>
                    </div>
                </div>
            </section>

            <section id='social_media' className='container'>
                <div className='row g-5 row-padding' style={rowPadding}>
                    <div className='col-md-6 mt-0'>
                        <div className='me-md-5 position-relative'>
                            {/* <StaticImage src='../images/edit-your-site.jpg' className='img-fluid position-relative shadow w-100' alt='Launch Site' style={{height: 320}}></StaticImage> */}
                            <img src={socialMediaMarketing} className='img-fluid position-relative w-100' alt='Launch Site' style={{height: 320, filter: "drop-shadow(rgba(0,0,0,.5)5px 5px 20px)"}}></img>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <h2 className='display-6 mb-4'>Social Media Marketing</h2>
                        <div className='line-divider'></div>
                        <p className='mb-4' style={pDefaultMargin}>
                            Digital marketing plays an important role in presenting your brand, product, and services to your target market. Our high-quality digital marketing Team will help you get your business an online exposure by creating a large traffic to your website where online browsers can be converted into online shoppers or potential customers.
                        </p>
                        <a className="btn btn-orange default-padding shadow text-capitalize" href="/services/social-media-marketing" role="button">Explore now</a>
                    </div> 
                </div>
            </section>
        </>    
    );
}