import React from 'react';
import { StaticImage } from 'gatsby-plugin-image'

const customStyles = {
    // height: "300px",
    padding: "25px 35px",
}

const rowPadding = {
    padding: "100px 0"
}

const imageFixHeight = {
    height: "200px",
    objectFit: "cover"
}

export default function WhyChooseUs() {
    return (
        <section id='feature_section' className='dot-pattern text-dark'>
            <div className='container'>
                <div className='row g-5 mt-0 row-padding' style={rowPadding}>

                    <div className='w-100 mt-0'>
                        <h2 className='display-5 text-capitalize text-center'>Why choose us</h2>
                    </div>

                    <div className='col-md-12 col-lg-4'>
                        <div className="card shadow-lg text-center">
                            <StaticImage src='../images/img-10.png' className='card-img-top' alt='Affordable Price' style={imageFixHeight}/>
                            <div className='card-body bg-white' style={ customStyles }>
                                <h5 className='card-title display-6 fs-5 fw-bold mt-3 mb-4'>Affordable price</h5>
                                <p className='lh-base text-start'>
                                    Setting up a website can be costly and stressful for many. GoogolWeb provides affordable and highly competitive website packages. With GoogolWeb you do not have to spend thousands of dollars just to start up and maintain a website.
                                </p>  
                            </div>
                        </div>
                    </div>

                    <div className='col-md-12 col-lg-4'>
                        <div className="card shadow-lg text-center">
                            <StaticImage src="../images/img-8.png" className="card-img-top" alt="DAP" style={imageFixHeight}/>
                            <div className="card-body bg-white" style={ customStyles }>
                                <h5 className="card-title display-6 fs-5 fw-bold mt-3 mb-4">High Quality Website</h5>
                                <p className='lh-base text-start'>
                                    GoogolWeb has a variety of high quality and modern looking web themes specifically designed for your business. Our website is well-designed, functional, easy to use, and optimized for mobile, search, and social web.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-12 col-lg-4'>
                        <div className="card shadow-lg text-center">
                            <StaticImage src='../images/img-9.png' className="card-img-top" alt="HAE" style={imageFixHeight}/>
                            <div className="card-body bg-white" style={ customStyles }>
                                <h5 className="card-title display-6 fs-5 fw-bold mt-3 mb-4">Excellent Service</h5>
                                <p className='lh-base text-start'>
                                    Our experienced team of web designers and web developers will ensure you get the best service for your website. You will get the desired results for your website from the start to launching your live website.
                                </p>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}