import * as React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import WhyChooseUs from "../components/WhyChooseUs"
import FrontendEditor from "../components/FrontendEditor"
import ClientReviews from "../components/ClientReviews"
import WhatWeOffer from "../components/WhatWeOffer"
import WhyGoogolWeb from "../components/WhyGoogolWeb"
import OurWorks from "../components/OurWorks"
import { SEO } from "../components/seo"


// markup
const IndexPage = () => {
  return (
      <Layout>
        <Hero></Hero>

        <WhyChooseUs></WhyChooseUs>

        <FrontendEditor></FrontendEditor>

        <WhatWeOffer></WhatWeOffer>

        <WhyGoogolWeb></WhyGoogolWeb>

        <OurWorks></OurWorks>

        <ClientReviews></ClientReviews>
        
      </Layout>
  )
}

export default IndexPage

export const Head = () => (
  <SEO />
)