import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const rowPadding = {
    padding: "150px 0"
}

const pDefaultMargin = {
    margin: "25px 0 0",
    fontSize: "18px",
    lineHeight: "1.5",
    letterSpacing: ".5px"
}


export default function WhyGoogolWeb() {
    return (
        <section id='feature_section--2' className='w-100 dot-pattern text-dark'>
            <div className='container'>
                <div className='row flex-row-reverse g-5 align-items-center row-padding' style={rowPadding}>
                    <div className='col-md-6'>
                        <StaticImage src='../images/mobile-friendly.png' className='img-fluid' alt='Mobile Friendy Web Design' />
                    </div>
                    <div className='col-md-6'>
                        <h2 className='display-5 mb-4 text-capitalize'>We build mobile friendy websites</h2>
                        <div className='line-divider'></div>
                        <p className='mb-4' style={pDefaultMargin}>
                            Your website speaks a lot about your Company and it needs to look perfectly beautiful and professional in all devices. Nothing is worst than having a website that looks terrible when viewed on a mobile device. With smartphones dominating our world, you will need a website that will give you a competitive advantage. 
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
