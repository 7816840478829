import React from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import '../styles/carousel.css'

export default function Home() {

    let clickToScroll = () => {
        const yOffset = -74; 
        const element = document.getElementById('feature_section');
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    };

    return (
        <section id='hero_section' className='hero-section'>
            <div className='container h-100'>
                <div className='row h-100 align-items-center'>
                    <div id='carouselExampleCaptions' className='carousel slide carousel-fade mt-5 mt-md-0 mt-lg-5' data-bs-ride='carousel' data-bs-pause='false'>   
                        <div className='carousel-inner'>
                            <div className='carousel-item active' data-bs-interval='8000'> 
                                <div className='container p-0'>
                                    <div className='row align-items-center'>
                                        <div className='col-sm-12 col-md-12 col-lg-6'>
                                            <div className='carousel-caption d-md-block me-md-3 text-center text-lg-start'>
                                                <h1 className='display-4 lh-1 mb-4 animate__animated animate__fadeInUp animate__delay-1s'>Start Your Website With GoogolWeb Today!</h1>
                                                <p className='fs-5 animate__animated animate__fadeInUp animate__delay-2s'>
                                                    Through creativity, innovation and strategic thinking we create websites that are beautiful, functional, usable and profitable . If you can imagine it, we can bring your website ideas to life.
                                                </p>
                                                <a className='btn btn-orange default-padding animate__animated animate__fadeInUp animate__delay-3s mt-2 shadow' href='/get-started' role='button'>Get Started Now</a>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-12 col-lg-6'>
                                            {/* <img src={heroImage1} className='d-block w-100 animate__animated animate__fadeInRight animate__delay-1s' alt='First'/> */}
                                            <StaticImage src='../images/hourglassfigure.png' className='animate__animated animate__fadeInRight animate__delay-1s' alt='Hourglass Figure Site'/>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='carousel-item' data-bs-interval='8000'>
                                <div className='container p-0'>
                                    <div className='row gx-5'>
                                        <div className='col-sm-12 col-md-12 col-lg-6 d-flex align-items-center'>
                                            {/* <img src={heroImage2} className='d-block w-100 animate__animated animate__fadeInRight animate__delay-1s' alt='First'/> */}
                                            <StaticImage src='../images/es-builders.png' className='d-block w-100 animate__animated animate__fadeInRight animate__delay-1s' alt='ESBuilders Site'/>

                                        </div>
                                        <div className='col-sm-12 col-md-12 col-lg-6'>
                                            <div className='carousel-caption d-md-block me-3 text-center text-lg-start'>
                                                <h1 className='display-4 lh-1 mb-3 animate__animated animate__fadeInUp animate__delay-1s'>A Trusted Partner For Your Digital Growth</h1>
                                                <p className='fs-5 animate__animated animate__fadeInUp animate__delay-2s'>
                                                    In this time of digital revolution, having a trusted and reliable partner to work with your business is a must to succeed. At GoogleWeb, we guarantee full confidence in our product and service.
                                                </p>

                                                <a className='btn btn-orange default-padding animate__animated animate__fadeInUp animate__delay-3s mt-2 shadow' href='/contact-us' role='button'>Contact Us</a>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='carousel-item' data-bs-interval='8000'>
                                <div className='container p-0'>
                                    <div className='row gx-5'>
                                        <div className='col-sm-12 col-md-12 col-lg-6'>
                                            <div className='carousel-caption d-md-block me-md-3 text-center text-lg-start'>
                                                <h1 className='display-4 lh-1 mb-3 animate__animated animate__fadeInUp animate__delay-1s'>Grow your business online</h1>
                                                <p className='fs-5 animate__animated animate__fadeInUp animate__delay-2s'>
                                                    Online visibility opens a wide door for business growth. It can reach great distances and generate sales beyond the four corners of your store.
                                                </p>
                                                
                                                <a className='btn btn-orange default-padding animate__animated animate__fadeInUp animate__delay-3s mt-2 shadow' href='/our-works' role='button'>See Our Works</a>
                                                
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-12 col-lg-6 d-flex align-items-center'>
                                            {/* <img src={heroImage3} className='d-block w-100 animate__animated animate__fadeInRight animate__delay-1s' alt='First'/> */}
                                            <StaticImage src='../images/the-mrs-gold.png' className='d-block w-100 animate__animated animate__fadeInRight animate__delay-1s' alt='First'/>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button> */}
                    </div>
                </div>
            </div>

            <div className='container scrollable d-none' id='scrollable' onClick={clickToScroll} onKeyDown={clickToScroll} aria-hidden='true'>
                <div className='chevron'></div>
                <div className='chevron'></div>
                <div className='chevron'></div>
            </div>
        </section>
    );
}