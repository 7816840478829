import React from 'react';
// import { StaticImage } from 'gatsby-plugin-image'
import TheMrsGold from '../images/the-mrs-gold.png'

const rowPadding = {
    padding: "150px 0"
}

const pDefaultMargin = {
    margin: "20px 0 40px 0",
    fontSize: "18px",
    lineHeight: "1.5",
    letterSpacing: ".5px"
}

const imgStyle = {
    // height: "700px",
    position: "relative",
    // top: "-100px",
    // left: "-120px"
    // right: "170px"
}

export default function FrontendEditor() {
    return (
        <section id='feature_section--1' className='w-100' style={{ overflow: "hidden" }}>
            <div className='container'>
                <div className='row flex-row-reverse g-lg-5' style={rowPadding}>

                    <div className='col-md-12 col-lg-6 position-relative mt-0'>
                        <img src={TheMrsGold} className='overlapImg' alt='TheMrsGold site in desktop monitor' style={imgStyle} />

                    </div>

                    <div className='col-md-12 col-lg-6 text-white'>
                        <h2 className='display-6'>Unique Approach to Web Design</h2>
                        <p className='' style={pDefaultMargin}>
                            Are you tired and bored at how your current website looks like or are you thinking about getting a website for your business? In this digital age of a very competitive market, online visibility is a key for many businesses to succeed and thrive. Here at Googolweb, our experienced team can help you design a fully responsive website, with modern design at a very reasonable cost to ensure it fits for its purpose and is custom made just for you.
                        </p>
                        <a className="btn btn-orange default-padding shadow" href="/our-work" role="button">See Our Works</a>
                    </div>

                </div>
            </div>
        </section>
    )
}
