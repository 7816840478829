import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

// import ESBuildersIMG from '../images/esbuilders.png'
// import TheMrsGoldIMG from '../images/themrsgold.png'
// import PerlaSuiteIMG from '../images/perlasuite.png'
// import HourglassFigureIMG from '../images/hourglassfigure.png'

const rowPadding = {
    padding: "150px 0"
}

export default function OurWorks() {
    return (
        <section id='our_works' className='container'>
            <div className='row g-5 justify-content-center mt-0 row-padding' style={rowPadding}>

                <div className='col-12 col-md-7 text-center mt-0'>
                    <h2 className='display-5 mb-3'>Our Works</h2>
                    <p className='fs-18'>
                        GoogolWeb contains a variety of website designs for different business types, 
                        that you can choose from and customize.
                    </p>
                </div>

                <div className='col-md-6'>
                    <div className="">
                        <StaticImage src='../images/hourglassfigure.png' className="img-fluid " alt="HourglassFigure Site"/>
                        <h5 className='pt-4 text-center'>Hourglass Figure</h5>
                        <a href='https://www.hourglassfigure.co.nz' className='text-center text-white' target='_blank' rel='noreferrer' style={{ textDecoration: "none" }}><p>Visit Website</p></a>
                    </div>
                </div>

                <div className='col-md-6'>
                    <div className="">
                        <StaticImage src='../images/perlasuite.png' className="img-fluid " alt="PerlaSuite Site"/>
                        <h5 className='pt-4 text-center'>Perla Suite</h5>
                        <a href='https://perlasuite.com' className='text-center text-white' target='_blank' rel='noreferrer' style={{ textDecoration: "none" }}><p>Visit Website</p></a>
                    </div>
                </div>

                <div className='col-md-6'>
                    <div className="">
                        <StaticImage src='../images/esbuilders.png' className="img-fluid " alt="ESBuilders Site" />
                        <h5 className='pt-4 text-center'>ES Builders</h5>
                        <a href='https://esbuilders.co.nz' className='text-center text-white' target='_blank' rel='noreferrer' style={{ textDecoration: "none" }}><p>Visit Website</p></a>
                    </div>
                </div>

                <div className='col-md-6'>
                    <div className="">
                        <StaticImage src='../images/themrsgold.png' className="img-fluid " alt="TheMrsGold Site"/>
                        <h5 className='pt-4 text-center'>The Mrs Gold</h5>
                        <a href='https://themrsgold.com' className='text-center text-white' target='_blank' rel='noreferrer' style={{ textDecoration: "none" }}><p>Visit Website</p></a>
                    </div>
                </div>

                <div className='d-flex justify-content-center'>
                    <a className="btn btn-orange default-padding text-capitalize" href="/our-work" role="button">See all works</a>
                </div>
            </div>
        </section>     
    )
}
